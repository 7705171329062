module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"public/page-data/":["cache-control: public, max-age=0, must-revalidate"],"static/":["cache-control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/public/intl","setup":{"path":"/opt/build/repo"},"defaultLanguageToRoot":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Qs3h6HlU9MsSzYyhuhZMTNWAPahNGV2r","devKey":"Qs3h6HlU9MsSzYyhuhZMTNWAPahNGV2r","host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"trackPage":false,"manualLoad":true},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-autonomy/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"dfp1t53x5luq","accessToken":"_WIF8mPXMw1OuMu3z1W29LRjDm09tyYpO51lXj6tOyw","host":"cdn.contentful.com","environment":"master"},"omsConfig":{"brandName":"Caraway","storeId":"xxx","omsId":"xxx","tenantId":"xxx","domain":"https://caraway.assembly-api.com"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl","setup":{"path":"/opt/build/repo"}},"siteMetadata":{"title":"Caraway","siteUrl":"https://www.carawayhome.com","description":"We’re on a mission to craft well-designed, non-toxic ceramic cookware that thoughtfully raises the standards of what you cook with. Discover Caraway cookware.","social":{"facebook":"carawayhome","instagram":"caraway_home","pinterest":"carawayhome","linkedin":"carawayhome","twitter":"carawayhome"},"contact":{"telephone":"6463890251","contactType":"Customer Service"}},"authConfig":{"magic":{"apiKey":"pk_live_3044B74C0125BDCE"}},"segmentConfig":{"prodKey":"Qs3h6HlU9MsSzYyhuhZMTNWAPahNGV2r","devKey":"Qs3h6HlU9MsSzYyhuhZMTNWAPahNGV2r","host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"trackPage":true,"manualLoad":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
