import { getSearchParam } from "../search-params";

export const create_UUID = () => {
  let dt = new Date().getTime();

  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (dt +
      Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

export const readCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const createCookie = (name, hours) => {
  let date = new Date();
  date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
  let expires =
    ";expires=" + date.toGMTString();

  const value = readCookie(name);

  if (!(value) || value === 'undefined') {
    document.cookie = name + "=" + create_UUID() + expires + "; path=/; domain=carawayhome.com";
  } else {
    document.cookie = name + "=" + value + expires + "; path=/; domain=carawayhome.com";
  }
}

export const createUTMCookie = (name, hours) => {

  const utm = getSearchParam(name)
  const value = readCookie(name);

  let date = new Date();
  date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
  let expires =
    ";expires=" + date.toGMTString();

  if (utm) {
    document.cookie = name + "=" + utm + expires + ";  path=/; domain=carawayhome.com";
    return
  }

  if (value) {
    document.cookie = name + "=" + value + expires + ";  path=/; domain=carawayhome.com";
  }
}