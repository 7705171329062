
import { readCookie } from '../cookie'

const pixel = (event) => {
  const cookies = ['FPC', 'ajs_anonymous_id', 'utm_source', 'utm_medium', 'utm_campaign']
  const values = [];
  const orderValue = null;
  const newUser = null;

  cookies.forEach(cookie => {
    values.push(readCookie(cookie))
  });

  let img = document.createElement('img');
  img.style="position: absolute; z-index: -9999999;"
  img.src = `https://pixel.pointmediatracker.com/kpi?c=caraway&kpi=${event}&tag_id=201&fpc=${values[0]}&user_id=${values[1] ? values[1] : null}&utm_source=${values[2] ? values[2] : null}&utm_medium=${values[3] ? values[3] : null}&utm_campaign=${values[4] ? values[4] : null}&order_value=${orderValue}&new=${newUser}`
  document.body.appendChild(img);
}

export default pixel
