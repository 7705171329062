import React from 'react'
import propTypes from 'prop-types'
import ConsentManagerWrapper from '~/components/Segment/ConsentManager'

import './static/css/layout.css'
import './static/fonts/fonts.css'
import { createCookie, createUTMCookie } from '~/utils/cookie'
import ppm from '~/utils/ppm'

/**
 * Wraps the apps page element with Segment's Consent Manager.
 *
 * See:
 * https://github.com/segmentio/consent-manager
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 * https://github.com/segmentio/consent-manager/issues/10#issuecomment-679896358
 */
export const wrapPageElement = ({ element }) => {
  return <ConsentManagerWrapper>{element}</ConsentManagerWrapper>
}

wrapPageElement.propTypes = {
  element: propTypes.element.isRequired
}

const addScript = (url, props) => {
  let script = document.createElement('script')
  script.src = url
  script.async = true

  if (props && props.id) script.id = props.id
  if (props && props.class) script.className = props.class
  if (props && props['data-id']) script.dataset.id = props['data-id']
  if (props && props.onload) script.onload = props.onload

  document.body.appendChild(script)
}

const getParam = param => new URLSearchParams(window.location.search).get(param)

export const onClientEntry = () => {
  addScript('https://www.googleoptimize.com/optimize.js?id=GTM-5H2FKPG')
  window.AddShoppersWidgetOptions = { loadCss: false, pushResponse: false }

  window.onload = () => {
    const html = document.querySelector('html')
    html.style.scrollPaddingTop = '118px'
    addScript(
      'https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=16006',
      {
        id: 'gorgias-chat-widget-install-v2'
      }
    )
    var geq = (window.geq = window.geq || [])
    geq.invoked = true
    geq.methods = ['page', 'suppress', 'trackOrder', 'identify', 'addToCart']
    geq.factory = method =>
      function () {
        var args = Array.prototype.slice.call(arguments)
        args.unshift(method)
        geq.push(args)
        return geq
      }
    for (var i = 0; i < geq.methods.length; i++) {
      var key = geq.methods[i]
      geq[key] = geq.factory(key)
    }
    geq.SNIPPET_VERSION = '1.5.1'
    setTimeout(() => {
      addScript(
        `https://s3-us-west-2.amazonaws.com/jsstore/a/WN2HZVK/ge.js${
          window.location.href.includes('vge=true') ? `?v=${Math.random()}` : ''
        }`,
        {
          onload: geq.page()
        }
      )
    }, 90000)
    if (process.env.GATSBY_OCTANE_AI_KEY) {
      addScript(
        `https://app.octaneai.com/${process.env.GATSBY_OCTANE_AI_KEY}/quiz.js`
      )
    }
    if (process.env.GATSBY_YOTPO_APP_KEY) {
      const onYotpoLoad = () => {
        if (window.Yotpo && window.Yotpo.API) {
          window.yotpoApi = new window.Yotpo.API(window.yotpo)
          return
        }
        setTimeout(onYotpoLoad, 1000)
      }

      addScript(
        `https://staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js`,
        {
          onload: onYotpoLoad
        }
      )
    }
    if (process.env.GATSBY_TALKABLE_SITE_ID) {
      const onTalkableLoad = () => {
        const paramPage = getParam('traffic_source')
        const detectedCpid = getParam('cpid')

        if (detectedCpid != null) {
          sessionStorage.setItem('cpid', detectedCpid)
        }

        window._talkableq = window._talkableq || []
        window._talkableq.push([
          'init',
          { site_id: process.env.GATSBY_TALKABLE_SITE_ID }
        ])
        window._talkableq.push([
          'authenticate_customer',
          {
            email: '',
            first_name: '',
            last_name: '',
            traffic_source: paramPage
          }
        ])
        window._talkableq.push(['register_affiliate', {}])
      }
      addScript(
        `https://d2jjzw81hqbuqv.cloudfront.net/integration/clients/${process.env.GATSBY_TALKABLE_SITE_ID}.min.js`,
        {
          onload: onTalkableLoad
        }
      )
    }
    addScript('https://js.afterpay.com/afterpay-1.x.js')

    const activateBEN = () => {
      var o = (window.tdl = window.tdl || [])
      if (!o.invoked) {
        ;(o.invoked = !0), // eslint-disable-line no-unused-expressions
          (o.methods = ['init', 'identify', 'convert']),
          (o.factory = function (n) {
            return function () {
              var e = Array.prototype.slice.call(arguments)
              return e.unshift(n), o.push(e), o
            }
          })
      }
      for (var e = 0; e < o.methods.length; e++) {
        var n = o.methods[e]
        o[n] = o.factory(n)
      }
      addScript('https://js.go2sdk.com/v2/tune.js')
      o.domain = 'https://www.inflcr.co'
      tdl.identify() // eslint-disable-line no-undef
    }
    activateBEN()

    const onGrinLoad = () => {
      window.Grin = window.Grin || (window.Grin = [])
    }
    addScript('https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js', {
      onload: onGrinLoad
    })

    const pixelLoad = () => {
      window.pixelezeData = window.pixelezeData || (window.Grin = {})
      pixelezeData.AccountId = 'c836375e-5150-4080-90de-ae4a00e9cff0' // eslint-disable-line no-undef
      pixelezeData.CustomerId = '' // eslint-disable-line no-undef
      pixelezeData.CustomerEmail = '' // eslint-disable-line no-undef
      let url =
        'https://pixeleze.com/run?PixelezeData=' +
        encodeURIComponent(JSON.stringify(pixelezeData)) // eslint-disable-line no-undef
      url += '&SourceUrl=' + encodeURIComponent(window.location.href)
      url +=
        '&Cookies=' +
        encodeURIComponent(
          document.cookie.replace(/(\s|^)(?!Pixeleze).+?=.+?(;|$)/g, '')
        )
      addScript(url)
    }
    pixelLoad()

    const onPodcornLoad = () => {
      window.podcornDataLayer = window.podcornDataLayer || []
      function podcorn() {
        window.podcornDataLayer.push(arguments)
      }
      podcorn('js', new Date())
      podcorn('config', '845a484c-299e-4ae4-bda7-57eaa0f820c4')
    }

    if (process.env.NODE_ENV === 'production') {
      addScript(
        'https://behaviour.podcorn.com/js/app.js?id=845a484c-299e-4ae4-bda7-57eaa0f820c4',
        {
          onload: onPodcornLoad
        }
      )

      addScript(
        'https://shop.pe/widget/widget_async.js#6335a778b1e59504cf2e1522',
        {
          id: 'AddShoppers'
        }
      )
    }

    createCookie('FPC', 7 * 24)
    createUTMCookie('utm_source', 7 * 24)
    createUTMCookie('utm_campaign', 7 * 24)
    createUTMCookie('utm_medium', 7 * 24)
    ppm('visit')
  }
}
