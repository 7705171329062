const newSearchParams = () => new URLSearchParams(window.location.search);

export const setSearchParam = (key, value) => {
  if (typeof window === "undefined") return;
  const searchParams = newSearchParams();
  searchParams.set(key, value);
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams}`
  );
};

export const deleteSearchParam = (key) => {
  if (typeof window === "undefined") return;
  const searchParams = newSearchParams();
  searchParams.delete(key);
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams}`
  );
};

export const getSearchParam = (key) => {
  if (typeof window === "undefined") return;
  const searchParams = newSearchParams();
  return searchParams.get(key);
};
