const colors = {
  black: '#233437',
  navy: '#1F3438',
  perracotta: '#D47555',
  sage: '#6B8060',
  cream: '#F7E6C1',
  white: '#FCFCFA',
  gray: '#C7C7C7',
  secondaryGray: '#535353',
  lightBlue: '#aec9d3',
  pink: '#FABBCB',
  turquoise: '#58D8D0',
  skyBlue: "#a7cad4",
  plum: '#6A2828',
  iconicsBlack: '#202620',
  marigold: '#F0B323',

}
const defaultButton = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
  fontSize: 16,
  lineHeight: '28px',
  whiteSpace: 'nowrap',
  padding: '10px 24px',
  letterSpacing: '0.05em',
  transition: '0.2s ease-in-out all',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textDecoration: 'none',
  border: 'none',
  borderRadius: 25
}

export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: ['768px', '992px', '1200px', '1440px'],
  fonts: {
    body: '"Moderat", system-ui, sans-serif',
    heading: '"Moderat", system-ui, sans-serif',
    primary: '"Moderat", system-ui, sans-serif',
    secondary: '"Suisse Works", system-ui, sans-serif'
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    regular: 400,
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  colors: {
    background: '#fcfcf9',
    text: colors.black,
    coolGray: '#C9C6B7',
    ...colors
  },
  styles: {
    root: {
      '*': {
        margin: 0,
        padding: 0,
        WebkitFontSmoothing: 'antialiased',
        textRendering: 'optimizeLegibility'
      },
      variant: 'text.body',
      a: { color: 'inherit', textDecoration: 'none' },
      button: { fontFamily: 'body' },
      'span.yotpo-icon.yotpo-icon-star.rating-star, span.yotpo-icon.review-star.yotpo-icon-star':
        {
          background:
            'url(//cdn.shopify.com/s/files/1/0258/6273/3906/t/328/assets/star.svg?v=2936979744462401209) center/cover no-repeat !important'
        },
      '.yotpo-stars': { display: 'flex' },
      '.rmz-chat-bubble': {
        zIndex: '1 !important'
      }
    }
  },
  buttons: {
    primary: {
      ...defaultButton,
      backgroundColor: colors.cream,
      color: colors.navy,
      minWidth: '250px',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.navy
      }
    },
    primarySmall: {
      ...defaultButton,
      backgroundColor: colors.cream,
      color: colors.navy,
      fontSize: '14px',
      height: '40px'
    },
    secondary: {
      ...defaultButton,
      fontWeight: 'normal',
      minWidth: '250px',
      backgroundColor: colors.navy,
      color: 'white',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.navy
      }
    },
    neutral: {
      ...defaultButton,
      fontWeight: 'bold',
      minWidth: '250px',
      backgroundColor: colors.white,
      color: colors.black,
      '&:hover': {
        backgroundColor: colors.black,
        color: colors.white
      }
    },
    tertiary: {
      ...defaultButton,
      backgroundColor: colors.perracotta,
      color: 'white',
      '&:hover': {
        backgroundColor: '#bf694d'
      }
    },
    tertiaryNav: {
      ...defaultButton,
      textTransform: undefined,
      fontWeight: undefined,
      height: 40,
      backgroundColor: colors.perracotta,
      color: 'white',
      '&:hover': {
        backgroundColor: '#bf694d'
      }
    },
    secondaryLarge: {
      ...defaultButton,
      backgroundColor: colors.black,
      color: 'white',
      fontSize: ['13px', null, '16px'],
      width: '250px',
      height: '48px',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.black
      }
    },
    checkout: {
      ...defaultButton,
      backgroundColor: colors.sage,
      color: 'white',
      fontSize: '16px',
      fontWeight: 'bold',
      width: '100%',
      height: '48px',
      '&:hover': {
        backgroundColor: colors.lightBlue,
        color: colors.black
      }
    },
    checkoutMarigold: {
      ...defaultButton,
      backgroundColor: colors.marigold,
      color: colors.navy,
      width: '100%',
      '&:hover': {
        backgroundColor: colors.navy,
        color: colors.white,
      }
    },
    outline: {
      ...defaultButton,
      backgroundColor: 'white',
      color: colors.navy,
      textTransform: 'none',
      fontWeight: '400',
      border: '1px solid black',
      width: 'fit-content',
      padding: '8px 45px',
      letterSpacing: '0.5px',
      fontSize: '16px',
      ':hover': {
        backgroundColor: colors.navy,
        color: 'white'
      }
    },
    outlineSmall: {
      ...defaultButton,
      backgroundColor: 'white',
      color: colors.navy,
      textTransform: 'none',
      fontWeight: '400',
      border: '1px solid black',
      width: 'fit-content',
      letterSpacing: '0.5px',
      height: '34px',
      fontSize: '14px',
      ':hover': {
        backgroundColor: colors.navy,
        color: 'white'
      }
    },
    underlined: {
      ...defaultButton,
      maxWidth: "fit-content",
      backgroundColor: 'transparent',
      color: "inherit",
      textTransform: 'none',
      letterSpacing: '0.5px',
      fontSize: 'inherit',
      border: 'transparent',
      textDecoration:"underline",
    },
    transparent: {
      ...defaultButton,
      backgroundColor: 'transparent',
      color: colors.navy,
      textTransform: 'none',
      fontWeight: '400',
      border: '1px solid black',
      width: 'fit-content',
      padding: '8px 45px',
      letterSpacing: '0.5px',
      fontSize: '16px',
      ':hover': {
        backgroundColor: colors.navy,
        color: 'white'
      }
    },
    iconicsblack: {
      ...defaultButton,
      width: 'fit-content',
      backgroundColor: colors.iconicsBlack,
      color: colors.white,
      textTransform: 'none',
      letterSpacing: '0.5px',
      fontSize: 'text.small',
      border: `1px solid ${colors.iconicsBlack}`,
      padding: '8px 45px',

      ':hover': {
        backgroundColor: 'transparent',
        color: colors.iconicsBlack,
        border: `1px solid ${colors.iconicsBlack}`,
      }
    },
    unstyled: {
      background: 'none',
      color: 'inherit',
      fontSize: 'inherit',
      padding: 0
    },
    marigold: {
      ...defaultButton,
      backgroundColor: colors.marigold,
      fontSize: ['13px', null, '16px'],
      color: colors.navy,
      paddingLeft: '40px',
      paddingRight: '40px',
      maxWidth: 'fit-content',
      height: '48px',
      ':hover': {
        backgroundColor: colors.navy,
        color: colors.white,
      }
    },
    marigoldalt: {
      variant: 'buttons.marigold',
      ':hover': {
        backgroundColor: 'transparent',
        color: colors.marigold,
        outline: `2px solid ${colors.marigold}`,
      }
    }
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)'
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted'
    }
  },
  layout: {
    container: {
      margin: 'auto',
      maxWidth: '1440px',
      padding: ['0 20px', null, '0 45px'],
      overflow: 'hidden',
      width: '100%'
    },
    small: {
      maxWidth: '1200px',
      padding: ['0 20px', null, '0 45px']
    },
    xsmall: {
      maxWidth: '1024px',
      padding: ['0 20px', null, '0 45px']
    },
    large: {
      maxWidth: '1600px',
      padding: ['0 20px', null, '0 45px']
    },
    tablet: {
      margin: 'auto',
      maxWidth: 'calc(1600px)',
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%'
    },
    fullWidth: {
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%',
      maxWidth: 'none'
    }
  },
  responsive: {
    mobile: {
      display: ['block', 'none', 'none']
    },
    mobileTablet: {
      display: ['block', 'block', 'none']
    },
    tablet: {
      display: ['none', 'block', 'none']
    },
    desktop: {
      display: ['none', 'none', 'block']
    },
    tabletDesktop: {
      display: ['none', 'block', 'block']
    }
  },
  text: {
    primaryLarge: {
      fontFamily: 'primary',
      fontWeight: 'regular',
      fontSize: ['32px', null, '56px'],
      lineHeight: ['38px', null, '64px'],
      color: 'navy'
    },
    primaryMedium: {
      fontFamily: 'primary',
      fontWeight: 'regular',
      fontSize: ['32px', null, '42px'],
      lineHeight: ['38px', null, '52px'],
      color: 'navy'
    },
    primarySmall: {
      fontFamily: 'primary',
      fontWeight: 'regular',
      fontSize: ['24px', null, '32px'],
      lineHeight: ['28px', null, '38px'],
      color: 'navy'
    },
    secondaryMedium: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['24px', null, '30px'],
      lineHeight: ['32px', null, '40px'],
      color: 'navy'
    },
    secondaryMediumBorder: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['24px', null, '30px'],
      lineHeight: ['32px', null, '40px'],
      color: 'navy',
      borderBottom: '1px solid #e3e3e3',
      paddingBottom: ['12px', null, '20px'],
      marginBottom: ['24px', null, '48px']
    },
    secondaryShMedium: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['20px', null, '26px'],
      lineHeight: ['28px', null, '34px'],
      color: 'navy'
    },
    secondarySmall: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['16px', null, '18px'],
      lineHeight: ['20px', null, '24px'],
      color: 'navy'
    },
    secondaryXSmall: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['12px'],
      lineHeight: ['20px'],
      color: 'navy'
    },
    secondaryLarge: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['32px', null, '36px'],
      lineHeight: ['42px', null, '47px'],
      color: 'navy'
    },
    secondaryXLarge: {
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: ['32px', null, '48px'],
      lineHeight: ['42px', null, '56px'],
      color: 'navy'
    },
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: [2, null, 3]
    },
    small: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: '16px',
      letterSpacing: '.3px'
    },
    xsmall: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: '14px',
      letterSpacing: '.3px'
    },
    large: {
      fontFamily: 'body',
      lineHeight: '36px',
      fontWeight: 'body',
      fontSize: '36px'
    },
    caps: {
      textTransform: 'uppercase'
    },
    featuredCardTitle: {
      color: 'text',
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '36px'
    },
    formInput: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    formLabel: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase'
    },
    formError: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase',
      color: colors.perracotta
    },
    link: {
      color: 'inherit',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textDecoration: 'none',
      letterSpacing: '2px',
      textTransform: 'uppercase'
    },
    h1: {
      color: 'text',
      fontFamily: 'secondary',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'text',
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: '30px',
      lineHeight: '40px'
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    collectionHeading: {
      color: 'text',
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '28px'
    },
    productTitle: {
      color: 'text',
      fontFamily: 'secondary',
      fontWeight: 'bold',
      fontSize: '44px',
      lineHeight: '48px'
    },
    footerHeading: {
      color: colors.cream,
      fontFamily: 'secondary',
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '10px',
      fontWeight: 'bold'
    },
    footerText: {
      color: colors.cream,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.025rem',
    }
  }
}
